<template>
    <b-navbar toggleable="lg" style="position: fixed; z-index: 99999; background-color: transparent; width: 100%;" >
        
    </b-navbar>
</template>

<script>

    export default {

        data() {
            return {}
        },
        methods: {

        },
        components: {

        },
        watch: {

        },
        mounted() {

        }

    }

</script>
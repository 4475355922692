<template>
    <div :class="contentWidth === 'boxed' ? 'container p-0' : null">
        <!--<nav-bar />-->
        <router-view />
    </div>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import NavBar from "./NavBar"
export default {
    components: {
        NavBar
    },
  setup() {
    const { contentWidth } = useAppConfig()
    return { contentWidth }
  },
}
</script>
